import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Client} from '../models/client';


@Injectable()
export class StoreService {

    constructor(private afs: AngularFirestore) {
    }

    getCollection(collection: string, ref) {
        const clientsCollection = this.afs.collection<Client>(collection, ref);

        return clientsCollection.snapshotChanges().pipe(
            map(actions => {
                return actions.map(a => {
                    const data = a.payload.doc.data();
                    const uid = a.payload.doc.id;
                    return {uid, ...data};
                });
            })
        );
    }

    updateData(collection, docId, data) {
        return this.afs.collection(collection).doc(docId).update(data);
    }

    deleteData(collection, docId) {
        return this.afs.collection(collection).doc(docId).delete();
    }

}
