import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-smoke',
  templateUrl: './smoke.component.html',
  styleUrls: ['./smoke.component.css']
})
export class SmokeComponent implements OnInit {

    email = '';
    password = '';
    errorMessage = '';
    error: {name: string, message: string} = {name: '', message: ''};
    isLoading = false;
    hide = true;

    constructor(public authService: AuthService, private router: Router) {}

    ngOnInit() {
        console.log('login init');
    }

    onLoginEmail(): void {
        this.isLoading = true;
        if (this.validateForm(this.email, this.password)) {
            this.authService.loginWithEmail(this.email, this.password)
                .then(() => {this.router.navigate(['/clients-list']); this.isLoading = false;})
                .catch(_error => {
                    this.isLoading = false;
                    this.error = _error;
                    this.router.navigate(['/smoke']);
                });
        }
    }

    validateForm(email: string, password: string): boolean {
        if (email.length === 0) {
            this.errorMessage = 'Введите email!';
            return false;
        }

        if (password.length === 0) {
            this.errorMessage = 'Пароль не должен быть пустым!';
            return false;
        }

        this.errorMessage = '';

        return true;
    }

}
