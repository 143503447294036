import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RegisterComponent} from './pages/register/register.component';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {WindowService} from './services/window.service';
import {ActivateComponent} from './pages/activate/activate.component';
import {ClientsListComponent} from './pages/clients-list/clients-list.component';
import {AuthService} from './services/auth.service';
import {SmokeComponent} from './pages/smoke/smoke.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {environment} from '../environments/environment';
import {StoreService} from './services/store.service';
import {ClientFormComponent} from './pages/clients-list/client.form.component';
import {ConfirmDialogComponent} from './common/confirm-dialog/confirm-dialog.component';
import {NgxMaskModule} from 'ngx-mask';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';



@NgModule({
    declarations: [
        AppComponent,
        RegisterComponent,
        ActivateComponent,
        ClientsListComponent,
        SmokeComponent,
        ClientFormComponent,
        ConfirmDialogComponent,
    ],
    entryComponents: [ClientFormComponent, ConfirmDialogComponent],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        BrowserModule,
        AppRoutingModule,
        MatButtonModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTableModule,
        MatDialogModule,
        MatRadioModule,
        MatPaginatorModule,
        MatSortModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxMaskModule.forRoot()
    ],
    providers: [WindowService, AuthService, StoreService, {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru-RU'}],
    bootstrap: [AppComponent]
})
export class AppModule {
}
