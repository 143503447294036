import {Component, OnInit} from '@angular/core';
import {WindowService} from '../../services/window.service';
import * as firebase from 'firebase';
import {Client} from '../../models/client';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {
    user: any;
    windowRef: any;
    verificationCode: string;
    client: Client;
    codeError = false;
    saveDataError = false;

    constructor(private win: WindowService, private router: Router, private dataService: DataService) {
        this.client = this.dataService.client;
        if (this.client == null) {
            this.router.navigate(['/register']);
        }
        this.client.cardNumber = 'GZ' + this.client.cardNumber;
    }

    ngOnInit() {
        this.windowRef = this.win.windowRef;
        if (!firebase.apps.length) {
            // console.log('re-init firebase');
            firebase.initializeApp(environment.firebase);
        }
        this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        this.windowRef.recaptchaVerifier.render();
    }


    sendLoginCode() {
        this.codeError = false;
        this.saveDataError = false;
        const appVerifier = this.windowRef.recaptchaVerifier;
        const num = this.client.phone;
        firebase.auth().signInWithPhoneNumber(num, appVerifier)
            .then(result => {
                this.windowRef.confirmationResult = result;
            })
            .catch(error => console.log(error));

    }

    verifyLoginCode() {
        this.windowRef.confirmationResult
            .confirm(this.verificationCode)
            .then(result => {
                const firestore = firebase.firestore();
                const settings = {/* your settings... */ timestampsInSnapshots: true};
                firestore.settings(settings);
                const timestamp = firebase.firestore.FieldValue.serverTimestamp();
                firestore.collection('/clients').doc(result.user.uid).set({
                    lastName: this.client.lastName,
                    firstName: this.client.firstName,
                    middleName: this.client.middleName,
                    cardNumber: this.client.cardNumber,
                    phone: this.client.phone,
                    processed: false,
                    createdAt: timestamp
                }).then(() => {
                    this.user = result.user;
                }).catch((error) => {
                    this.saveDataError = true;
                    console.log(error);
                });
            })
            .catch(error => {
                this.codeError = true;
                console.log(error, 'Incorrect code entered?');
            });
    }
}
