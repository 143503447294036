import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable()
export class AuthService {
    private userDetails: firebase.User = null;

    constructor(private afAuth: AngularFireAuth, private router: Router) {
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.userDetails = user;
                // console.log(this.userDetails);
            } else {
                this.userDetails = null;
            }
        });
    }

    isLoggedIn() {
        return this.userDetails != null;
    }

    getUser() {
        return this.userDetails;
    }

    loginWithEmail(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .then((user) => {
                console.log('logged', user);
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }

    signOut(): void {
        this.afAuth.auth.signOut();
        this.router.navigate(['/']);
    }
}
