import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RegisterComponent} from './pages/register/register.component';
import {ActivateComponent} from './pages/activate/activate.component';
import {ClientsListComponent} from './pages/clients-list/clients-list.component';
import {SmokeComponent} from './pages/smoke/smoke.component';
import {AdminGuard} from './guards/admin.guard';

const routes: Routes = [
    {path: '', redirectTo: 'register', pathMatch: 'full'},
    {path: 'register', component: RegisterComponent},
    {path: 'activate', component: ActivateComponent},
    {path: 'smoke', component: SmokeComponent},
    {path: 'clients-list', component: ClientsListComponent, canActivate: [AdminGuard]},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
