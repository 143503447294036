import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Client} from '../../models/client';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    formGroup: FormGroup;
    client: Client;

    constructor(private formBuilder: FormBuilder, private router: Router, private dataService: DataService) {
    }

    ngOnInit() {
        this.client = this.dataService.client != null ? this.dataService.client : new Client();
        this.formGroup = this.formBuilder.group({
            lastName: [this.client.lastName, [Validators.required, Validators.pattern('[а-яА-Я ]*')]],
            firstName: [this.client.firstName, [Validators.required, Validators.pattern('[а-яА-Я ]*')]],
            middleName: [this.client.middleName, [Validators.required, Validators.pattern('[а-яА-Я ]*')]],
            phone: [this.client.phone, [Validators.required, Validators.pattern('^[+7]{2}[0-9]{10}$')]],
            // GZ0000000001 - GZ0000054796
            cardNumber: [this.client.cardNumber, [Validators.required, Validators.pattern('^(0000)[0-9]{6}')]],
        });
    }

    onSubmit() {
        const controls = this.formGroup.controls;

        if (this.formGroup.invalid) {
            Object.keys(controls)
                .forEach(controlName => controls[controlName].markAsTouched());

            return;
        }

        this.dataService.client = this.formGroup.value;
        this.router.navigate(['/activate']);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];

        return control.invalid && control.touched;
    }
}
