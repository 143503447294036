import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {StoreService} from '../../services/store.service';
import * as XLSX from 'xlsx';
import {ClientFormComponent} from './client.form.component';

import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatRadioChange} from '@angular/material/radio';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


import {ConfirmDialogComponent} from '../../common/confirm-dialog/confirm-dialog.component';
import {Client} from '../../models/client';
import {SelectionModel} from '@angular/cdk/collections';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-clients-list',
    templateUrl: './clients-list.component.html',
    styleUrls: ['./clients-list.component.css']
})
export class ClientsListComponent implements OnInit, AfterViewInit {
    @ViewChild('TABLE', {read: ElementRef}) table: ElementRef;
    displayedColumns = ['select', 'createdAt', 'lastName', 'firstName', 'middleName', 'phone', 'cardNumber', 'processed', 'operations'];
    dataSource = new MatTableDataSource<Client>();
    selection = new SelectionModel<Client>(true, []);

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    endDate: Date;
    private isProcessedClients = false;
    private clientsSubscription: Subscription;

    constructor(private store: StoreService, private dialog: MatDialog) {
    }

    ngOnInit() {
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Client): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.uid + 1}`;
    }

    process() {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false
        });
        dialogRef.componentInstance.confirmMessage = 'Точно хотите обработать все выделенные записи?';

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(this.selection);
                this.selection.selected.forEach((client) => {
                    console.log(client);
                    this.processClient(client);
                });
            }
            dialogRef = null;
        });
    }

    processClient(client: Client): void {
        client.processed = true;
        this.store.updateData('clients', client.uid, client);
    }

    ngAfterViewInit(): void {
        this.changeStore(false);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    dateFilter() {
        this.changeStore(this.isProcessedClients);
    }

    changeStore(isProcessed: boolean): void {
        this.isProcessedClients = isProcessed;

        if (this.clientsSubscription != null ) {
            this.clientsSubscription.unsubscribe();
        }
        if (this.endDate != null) {
            this.clientsSubscription = this.store.getCollection('clients', ref => ref
                .where('processed', '==', isProcessed)
                .where('createdAt', '<=', this.endDate)
                .orderBy('createdAt', 'desc')).subscribe(data => {
                this.dataSource.data = data;
            });
        } else {
            this.clientsSubscription = this.store.getCollection('clients', ref => ref
                .where('processed', '==', isProcessed)
                .orderBy('createdAt', 'desc')).subscribe(data => {
                this.dataSource.data = data;
            });
        }
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    openEditDialog(data: any) {
        this.dialog.open(ClientFormComponent, {
            width: '500px',
            data: data
        });
    }

    delete(data: any) {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false
        });
        dialogRef.componentInstance.confirmMessage = 'Точно хотите удалить эту запись?';

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.store.deleteData('clients', data.uid).then();
            }
            dialogRef = null;
        });
    }

    exportAsExcel() {
        // converts a DOM TABLE element to a worksheet
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);

        console.log(ws);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Активированные карты');

        /* save to file */
        XLSX.writeFile(wb, 'export-cards.xlsx');

    }

    onChange(mrChange: MatRadioChange) {
        this.dataSource.data = [];
        if (mrChange.value === '1') {
            this.changeStore(false);
        }

        if (mrChange.value === '2') {
            this.changeStore(true);
        }
    }

}
