import {Component, Inject, OnInit} from '@angular/core';
import {StoreService} from 'src/app/services/store.service';

import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Client} from '../../models/client';


@Component({
    selector: 'app-edit-dialog',
    template: `
        <div class="form-container">
            <mat-form-field>
                <input matInput [(ngModel)]="newData.lastName" placeholder="Фамилия">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="newData.firstName" placeholder="Имя">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="newData.middleName" placeholder="Отчество">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="newData.phone" placeholder="Телефон">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="newData.cardNumber" placeholder="Номер карты">
            </mat-form-field>
            <div>
                <mat-checkbox [(ngModel)]="newData.processed">Обработан</mat-checkbox>&nbsp;&nbsp;&nbsp;
            </div><br>
            <button mat-raised-button color="primary" (click)="save()">Сохранить</button>
        </div>
    `,
    styles: [`
        .form-container {
            display: flex;
            flex-direction: column;
        }

        .form-container > * {
            width: 100%;
        }`]
})
export class ClientFormComponent implements OnInit {
    newData: Client;
    collection = 'clients';

    ngOnInit() {
    }

    constructor(private store: StoreService, public dialogRef: MatDialogRef<ClientFormComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.newData = Object.assign({}, data);
        delete this.newData.uid;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save(): void {
        this.store.updateData(this.collection, this.data.uid, this.newData);
        this.dialogRef.close();
    }

}
